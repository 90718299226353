<template>
    <div>
      <div class="header">
          <h3 class="subheader-title"><strong>PROJECT LOCATIONS</strong></h3>
          <div class="logo"><img src="/img/logo-kopernik-2.png"></div>
      </div>     
      <div class="map">
          <gmap-map
          :center="center"
          :zoom="5"
          style="width:100%; height:480px"
          @click="infoWindowOpened = false"
          >
          <gmap-marker
              v-for="(marker, index) in markers"
              :key="index"
              :position="marker.position"
              :icon="marker.icon"
              @click="showInfoWindow(marker)"
          />
          <gmap-info-window
              :options="infoWindowOptions"
              :position="infoWindowPosition"
              :opened="infoWindowOpened"
              @closeclick="infoWindowOpened = false"
          >
              <!-- <h3>{{ infoWindowData.title }}</h3> -->
              <img :src="infoWindowData.photo"/>
              <h3>{{ infoWindowData.title }}</h3>
              <h4>{{ infoWindowData.location }}</h4>
          </gmap-info-window>
          </gmap-map>
      </div>
      <section class="legend">
        <h2>Legend:</h2>
        <ul>
          <li>
            <span class="agriculture"></span>
            Agriculture and Fisheries [20]
          </li>
          <li>
            <span class="energy"></span>
            Energy and Environment [8]
          </li>
          <li>
            <span class="micro"></span>
            Micro-Entrepreneurship [5]
          </li>
          <li>
            <span class="health"></span>
            Water, Sanitation & Health [7]
          </li>
          <li>
            <span class="education"></span>
            Education [1]
          </li>
        </ul>
      </section>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  
  export default {
    data () {
      return {
        center: { lat: -0.9565, lng: 116.6948 }, 
        markers: [],
        infoWindowData: {},
        infoWindowPosition: {},
        infoWindowOpened: false,
        infoWindowOptions: { pixelOffset: { width: 0, height: -35 }, maxWidth: 300, maxHeight: 200 }
      }
    },
    created () {
      this.loadMarkersFromJson()
    },
    methods: {
      loadMarkersFromJson () {
        // Load markers data from your JSON file
        axios.get('/markers.json').then(response => {
          this.markers = response.data.map(marker => {
            return {
              position: { lat: marker.lat, lng: marker.lng },
              icon: {
                url: this.getMarkerIconUrl(marker.category),
                scaledSize: { width: 30, height: 30 }
              },
              data: marker
            }
          })
        })
      },
      getMarkerIconUrl (category) {
        let url = ''
        switch (category) {
          case 'energy':
            url = 'https://maps.google.com/mapfiles/ms/icons/blue.png'
            break
          case 'agriculture':
            url = 'https://maps.google.com/mapfiles/ms/icons/green.png'
            break
          case 'health':
            url = 'https://maps.google.com/mapfiles/ms/icons/orange.png'
            break
          case 'micro':
            url = 'https://maps.google.com/mapfiles/ms/icons/yellow.png'
            break
          case 'education':
            url = 'https://maps.google.com/mapfiles/ms/icons/lightblue.png'
            break
          default:
            url = 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png'
        }
        return url
      },
      showInfoWindow (marker) {
        this.infoWindowData = marker.data
        this.infoWindowPosition = marker.position
        this.infoWindowOpened = true
      }
    }
  }
</script>

<style>
/*   #app {
    
  } */
  div {
    display: block;
  }
  /* Map */
  .map .vue-map-container {
    margin-top: 60px;
    height: 80vh;
    width: 100%;
    position: initial;
  }
  .vue-map-container .gm-ui-hover-effect{
    display: none!important;
  }

  /* Header */
  .header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #1867c0;
    z-index: 1;
  }
  .subheader-title {
      margin: 0;
      padding: 12px 0 0 10px;
      font-size: 1.2rem;
      font-weight: 500;
      color: #ffffff;
  }
  .logo img{
    position: inherit;
    height: 25px;
    margin: 10px 10px 0 0;
  }
  h1, h2, h3 {
    color: #FF9900;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    /* text-transform: uppercase; */
    right: 0;
    transition: .3s;
  }
  h4 {
    color: #00c4de;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
  }
  /* Legend */
  .legend {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
  }

  .legend h2 {
    font-size: 12px;
    margin-bottom: 5px;
    color: #392d23;
  }

  .legend ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .legend li {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .legend span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .legend .agriculture {
    background-color: rgb(0, 224, 0);
  }

  .legend .health {
    background-color: orange;
  }

  .legend .energy {
    background-color: rgb(90, 90, 212);
  }

  .legend .education {
    background-color: #5dd3d3;
  }

  .legend .micro {
    background-color: rgb(250, 250, 0);
  }
  
   /* Responsive */
   @media screen and (max-width: 768px) {
    .legend {
      top: auto;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    .legend h2 {
      font-size: 14px;
    }

    .legend li {
      font-size: 12px;
    }

    .legend span {
      width: 15px;
      height: 15px;
    }
  }
</style>