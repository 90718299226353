<template>
  <div id="app">
    <ProjectMap />
  </div>
</template>

<script>
import ProjectMap from './components/ProjectMap.vue'

export default {
  components: {
    ProjectMap
  }
}
</script>
