import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    /* key: 'AIzaSyAvmR8dJHhJVx0u5yEaM60W4g9qg5x8mjI', */
    key: "AIzaSyAovhxjkjJX16jhgBhuFGJTlIdfJZ8uYCY",
    libraries: 'places'
  }
})

Vue.config.productionTips = false

new Vue({
  render: h => h(App),
}).$mount('#app')
